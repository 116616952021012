import Image from "next/image";
import { LeadPopUpButton } from '../shared/lead/LeadPopUpButton';
import { ElemenetType } from '../../lib/constants';
import styles from '../style/home/whyChooseus.module.css';
import { useGlobalContext } from "@/lib/context";
export function WhyChooseUS(){
    const {props:{isMobileView}} = useGlobalContext();
    return (
        <section className="section whyInterior bgGray">
            <div className="container">
                <h2 className="heading textCenter font26 text222 f500 mb12 fontSM20 ">Why Choose Us</h2>
                <p className="subHeading textCenter font16 text666 fontSM14">With us, you experience the power of ideas, design and craftsmanship come alive.</p>
                    <div className={!isMobileView ? `${styles.whyInteriorBox} displayGrid grid5`: `${styles.whyInteriorBox} displayGrid` }>
                        <div className={!isMobileView ? `${styles.whyItem} plr` : styles.whyItem}>
                        <figure><Image
                                src="/assets/images/madeOrder.svg"
                                height={isMobileView ? 40 :50}
                                width={isMobileView ? 38 : 47}
                                alt='made order'
                                 /></figure>
                            <h3 className={"f500 font16 text000 mb12 mt16 textCenter lineheight20" }>Made to Order</h3>
                            <p className="textCenter">We create personalised<br/>spaces that cater to your<br/>every requirement.</p>
                        </div>
                    
                        <div className={!isMobileView ? `${styles.whyItem} plr` : styles.whyItem}>
                        <figure><Image
                                src="/assets/images/lowestPrice.svg"
                                height={isMobileView ? 40 : 50}
                                 width={isMobileView ? 56 : 70}
                                alt='lowest price'
                                 /></figure>
                            <h3 className={"f500 font16 text000 mb12 mt16 textCenter lineheight20"}>Lowest Prices<br/>Guaranteed</h3>
                            <p className="textCenter">We provide the best<br/>possible solutions that<br/>suit your finances.</p>
                        </div>
                    
                        <div className={!isMobileView ? `${styles.whyItem} plr` : styles.whyItem}>
                        <figure><Image
                                src="/assets/images/qualityChecks.svg"
                                height={isMobileView ? 40 : 50}
                                 width={isMobileView ? 40 : 50}
                                alt='auality checks'
                                 /></figure>
                            <h3 className={"f500 font16 text000 mb12 mt16 textCenter lineheight20"}>Quality Checks<br/>At Every Step</h3>
                            <p className="textCenter">We guarantee thorough<br/>quality checks till project<br/>completion</p>
                        </div>
                    
                        <div className={!isMobileView ? `${styles.whyItem} plr` : styles.whyItem}>
                        <figure><Image
                                src="/assets/images/timelyDelivery.svg"
                                height={isMobileView ? 40 : 50}
                                 width={isMobileView ? 42 : 52}
                                alt='timely delivery'
                                 /></figure>
                            <h3 className={"f500 font16 text000 mb12 mt16 textCenter lineheight20"}>Timely Delivery<br/>Assurance</h3>
                            <p className="textCenter">We proactively work on<br/>commitments to maintain our benchmark of ontime delivery</p>
                        </div>
                        <div className={!isMobileView ? `${styles.whyItem} plr` : styles.whyItem}>
                        <figure><Image
                                src="/assets/images/10yearWarranty.svg"
                                height={isMobileView ? 40 : 50}
                                 width={isMobileView ? 34 : 42}
                                alt='10 year warranty'
                                 /></figure>
                            <h3 className={"f500 font16 text000 mb12 mt16 textCenter lineheight20"}>11-Year<br/>Warranty</h3>
                            <p className="textCenter">We invigorate client<br/>relationships by offering<br/>warranties that last a decade.</p>
                        </div>
                    </div>
                    <LeadPopUpButton ElemenetType={ElemenetType.button}  ButtonClass={`${styles.whyChooseBtn} btn btnPrimery mxAuto`} />
            </div>
        </section>
    );
}